export interface ActiveRoute {
  source: string;
  destination: string;
}

export enum ActiveRouteTypes {
  HOME = '/',
  OFFERS = '/offers',
  ACCOUNT = '/account',
  SHOPPING = '/shopping',
}

export const activeRouteData = [
  {
    source: '/a-point-catalog',
    destination: ActiveRouteTypes.OFFERS,
  },
  {
    source: '/account',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/brands',
    destination: ActiveRouteTypes.SHOPPING,
  },
  {
    source: '/category',
    destination: ActiveRouteTypes.SHOPPING,
  },
  {
    source: '/delivery-tracking',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/complete-your-profile',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/coupons',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/forgot-password',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/forgot-username',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/group-order',
    destination: ActiveRouteTypes.SHOPPING,
  },
  {
    source: '/list',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/link-social-account',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/login',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/logout',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/my-account',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/my-subscriptions',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/myshop',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/offers',
    destination: ActiveRouteTypes.OFFERS,
  },
  {
    source: '/order-details',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/order-history',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/order-tracking',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/product',
    destination: ActiveRouteTypes.SHOPPING,
  },
  {
    source: '/promotions',
    destination: ActiveRouteTypes.OFFERS,
  },
  {
    source: '/return-details',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/return-replacement',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/shopping',
    destination: ActiveRouteTypes.SHOPPING,
  },
  {
    source: '/signup',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/solution',
    destination: ActiveRouteTypes.SHOPPING,
  },
  {
    source: '/subscription-details',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/subscription-plan',
    destination: ActiveRouteTypes.OFFERS,
  },
  {
    source: '/subscription-profile',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/subscriptions',
    destination: ActiveRouteTypes.ACCOUNT,
  },
  {
    source: '/promo-listing',
    destination: ActiveRouteTypes.OFFERS,
  },
  {
    source: '/return-order',
    destination: ActiveRouteTypes.ACCOUNT,
  },
];
