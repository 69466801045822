import { Breakpoints } from '@nextgen-web-framework/all';

let selectedClass = '';

export const onItemScroll = () => {
  const removeClass = document.querySelectorAll('[data-tab-scroll-id]');
  removeClass.forEach((item) => {
    const selClass = item.classList.toString().match(/_selected\w+/);
    if (selClass?.[0]) {
      selectedClass = selClass?.[0];
      item.classList.remove(selectedClass);
    }
    item.classList.remove('active');
  });
  const currentScrollTop = window.scrollX || document.documentElement.scrollTop;
  const sections = document.querySelectorAll<HTMLElement>('[data-section-scroll-id]');

  sections.forEach((section: HTMLElement) => {
    const sectionHeight = section.offsetTop;
    const sectionId = section.getAttribute('data-section-scroll-id');
    const sectionEnd = sectionHeight + section.offsetHeight;

    const tabElement = document.querySelector(`[data-tab-scroll-id='${sectionId}']`);

    if (currentScrollTop > sectionHeight && currentScrollTop <= sectionEnd) {
      tabElement?.classList.add('active');
      if (selectedClass) {
        tabElement?.classList.add(selectedClass);
      }
    } else {
      tabElement?.classList.remove('active');
    }
  });
};

export const onTabClick = (e: React.KeyboardEvent | React.MouseEvent, id: string, viewport: Breakpoints) => {
  const removeClass = document.querySelectorAll('[data-tab-scroll-id]');
  removeClass.forEach((item) => {
    item.classList.remove('active');
  });
  const elementPosition = document.getElementById(id)?.getBoundingClientRect().top as number;

  const HEADER_HEIGHT_MD = 154;
  const HEADER_HEIGHT_DEFAULT = 110;

  const headerHeight = viewport === Breakpoints.MD ? HEADER_HEIGHT_MD : HEADER_HEIGHT_DEFAULT;

  const position = elementPosition + window?.scrollY - headerHeight;
  window.scroll({
    top: position,
    behavior: 'smooth',
  });
  const section = document.getElementById(id)?.getAttribute('data-section-scroll-id');

  if (id === section) {
    document.querySelector(`[data-tab-scroll-id='${section}']`)?.classList.add('active');
  }
};
