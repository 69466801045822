import { useState, useEffect, ReactNode, JSX } from 'react';
import { Logger, EventService, StorageService, ConfigService } from '@nextgen-web-framework/core';
import { useRouter } from 'next/navigation';
import { debounce } from '@commerce-search-webcomponents/sdk';
import { getAccountTypeData, useAddressUpdate } from '@commerce-webcomponents/ui';
import { HeaderContainer, MobileTopBarNavigation, CmsComponent } from '@commerce-webcomponents/ui-composite';
import { WebEventsEnum, WebEventsVersionsEnum } from '@commerce-webcomponents/sdk';
import {
  useBreakpoint,
  Breadcrumb,
  Breakpoints,
  NotificationBar,
  Theme,
  useNextgenRouter,
  useTranslation,
  useSession,
} from '@nextgen-web-framework/all';
import { CMSTransformerDataType, CookieStorageKeys } from '../../types/common';
import { LayoutScrollType, getChangedLocaleUrl, useLayoutScroll } from '../../utils/LayoutUtil';
import AnnouncementBar from '../AnnouncementBar/AnnouncementBar';
import { useLayoutUtility } from '../../components/LayoutUtilityProvider/LayoutUtilityProvider';
import { useAppSelector, AppState } from '../../feature/store';
import { onItemScroll } from '../../utils/scrollUtil';
import { activeRouteData, ActiveRoute } from '../../types/mobileNavigation';
import styles from './Layout.module.scss';
import AdaChatbot from 'components/AdaChatbot/AdaChatbot';
import { getContentEssentials } from 'utils/getEssentials';

interface ILayoutProps {
  children: ReactNode;
  hasTopMobileHeader: boolean;
  headerProps?: HeaderProps;
  footerProps?: FooterProps;
  backHandler?: (e: React.KeyboardEvent | React.MouseEvent) => void;
  // to do-- crumbsData props type may replace with Breadcrumb proptypes once they are export from Breadcrumb packages.
  crumbsData?: React.ComponentProps<typeof Breadcrumb>;
  hideFooter?: boolean;
  hideMobileBottomNavigation?: boolean;
  hideSiteNavigation?: boolean;
  hideLocationSelector?: boolean;
  isStaticLocationSelector?: boolean;
  headerTitle?: string;
  header?: CMSTransformerDataType;
  footer?: CMSTransformerDataType;
  /**
   * Use this property to forcefully hide the notifications
   * One of such use case is in NotFound page
   */
  hideNotifications?: boolean;
}
interface HeaderProps {
  isStaticHeader?: boolean;
  isBackNavigation?: boolean;
  hasCart?: boolean;
  hasAmwayLogo?: boolean;
  hasSearchBar?: boolean;
  moreActionContent?: React.ReactNode;
  headerElement?: JSX.Element;
  onScrollChange?: (scrolled: boolean) => void;
  hasTabletScrolledHeaderBody?: boolean;
}

interface FooterProps {
  hideFooterNavigation?: boolean;
  hideSocialIcon?: boolean;
  hideLegalLink?: boolean;
}
const logger = new Logger();
const Layout = (props: ILayoutProps) => {
  const router = useNextgenRouter();
  const nextRouter = useRouter();
  const t = useTranslation();
  const cookieStorage = StorageService.getCookieStorage();
  const { scrollType, scrollPosition, prevScrollPosition, setPrevScrollPosition, handleScrollType } = useLayoutScroll();
  const [activeRoute, setActiveRoute] = useState(router.pathname as string);
  const [stickyBottomHeader, setStickyBottomHeader] = useState(false);
  const [mobileTopBarScroll, setMobileTopBarScroll] = useState(false);
  const [hasImpersonationBanner, setHasImpersonationBanner] = useState(false);
  const [mobileBottomNavigation, setMobileBottomNavigation] = useState(false);
  const [isNotificationClosed, setIsNotificationClosed] = useState(true);
  const [isMePanelDrawerOpen, setIsMePanelDrawerOpen] = useState(false);
  const { isStickyHeaderAvailable, stickyBottomHeaderHeight, updateUtility } = useLayoutUtility();
  const [isNotificationError, setIsNotificationError] = useState(false);
  const myList = useAppSelector((state: AppState) => state.commerceMyList?.myList);
  const viewPortType = useBreakpoint() as Breakpoints;
  const { session } = useSession();
  const getHeader = () => (Array.isArray(props.header) ? props.header[0] : props.header) || {};
  const headerInit = getHeader();
  const footerInit =
    (Array.isArray(props.footer) && props.footer[0].footer ? props.footer[0].footer : props.footer) || {};
  const { hideNotifications } = props;
  const configs = ConfigService.getConfigs();
  const { updateAddress } = useAddressUpdate();

  const [header, setHeader] = useState(headerInit);
  const [footer, setFooter] = useState(footerInit);

  const getHeaderFooterData = async () => {
    const headerResponse = await getContentEssentials({
      locale: router?.params?.locale as string,
      uId: 'header',
    });
    const footerResponse = await getContentEssentials({
      locale: router?.params?.locale as string,
      uId: 'footer',
      params: [{ key: 'query', value: JSON.stringify({ account_type: { account_type: session?.type } }) }],
    });
    return { headerData: headerResponse?.data, footerData: [footerResponse?.data] };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { headerData, footerData } = await getHeaderFooterData();
        setHeader(headerData);
        setFooter(footerData);
      } catch (err) {
        logger.log(`Failed with error:`, JSON.stringify(err));
      }
    };
    fetchData();
  }, [session.type, router?.params?.locale]);

  useEffect(() => {
    setPrevScrollPosition(window.scrollY);

    document.addEventListener('scroll', () => {
      debounce(5, () => {
        handleScrollType();
        handleNavigation();
      });
    });
  }, [viewPortType]);

  useEffect(() => {
    const getClosestMatchingUrl = (items: ActiveRoute[], targetUrl: string) =>
      items.find((item) => {
        const sourceExp = `^(\\${item.source}).?`;
        const regex = new RegExp(sourceExp, 'i');
        return regex.test(targetUrl);
      });
    const activeItem = getClosestMatchingUrl(
      activeRouteData,
      router.pathname?.replace(`/${router.params?.locale}`, '') as string,
    );
    setActiveRoute(
      activeItem
        ? (activeItem.destination as string)
        : (router.pathname?.replace(`/${router.params?.locale}`, '/') as string),
    );
  }, [router.pathname]);

  useEffect(() => {
    if (props?.headerProps?.onScrollChange) {
      props?.headerProps?.onScrollChange(mobileTopBarScroll);
    }
    setStickyBottomHeader(!!getStickyBottomHeaderElement());
    updateUtility({
      isContentScrolled: mobileTopBarScroll,
    });
  }, [mobileTopBarScroll]);

  useEffect(() => {
    const element = getStickyBottomHeaderElement();
    if (stickyBottomHeaderHeight !== element?.clientHeight) {
      updateUtility({
        stickyBottomHeaderHeight: element?.clientHeight,
      });
    }
    if (isStickyHeaderAvailable !== !!element) {
      updateUtility({
        isStickyHeaderAvailable: !!element,
      });
    }
  });

  useEffect(() => {
    const token = EventService.subscribe(
      `web.domain.${WebEventsEnum.UpdateCheckoutProfileAddress}`,
      WebEventsVersionsEnum['1.0.0'],
      () => updateAddress(),
    );
    return () => {
      EventService.unsubscribe(token);
    };
  }, []);

  //Subscribe to the ErrorMessage Notification and sets the state if this event is published anywhere in the app.
  useEffect(() => {
    const token = EventService.subscribe(
      `web.domain.${WebEventsEnum.ErrorMessage}`,
      WebEventsVersionsEnum['1.0.0'],
      () => setIsNotificationError(true),
    );
    return () => {
      EventService.unsubscribe(token);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition === 0) {
      setMobileTopBarScroll(false);
      stickyHeaderhandler('sticky-top-bar-positions', 'sticky-top-bar');
      setMobileBottomNavigation(false);
    }
  }, [scrollPosition]);

  function getStickyBottomHeaderElement() {
    return document.querySelector('#main-content .sticky-bottom-bar');
  }

  const handleNavigation = () => {
    const currentScrollPosition = window.scrollY || document.documentElement.scrollTop;
    if (currentScrollPosition > 0) {
      if (prevScrollPosition > window.scrollY) {
        handleStickyBottomHeader(LayoutScrollType.UP);
      } else if (prevScrollPosition < window.scrollY) {
        stickyHeaderhandler('sticky-top-bar', 'sticky-top-bar-positions');
        setMobileTopBarScroll(true);
        handleStickyBottomHeader(LayoutScrollType.DOWN);
      }
      const topbarElement = document.querySelector('#main-content .sticky-top-bar-positions');
      if (topbarElement) {
        onItemScroll();
      }
    }
  };
  const reloadPage = () => {
    router.refresh();
  };
  const handleStickyBottomHeader = (scrollDirection: string) => {
    if (getStickyBottomHeaderElement()) {
      const result = document.getElementsByClassName('sticky-bottom-bar');
      const elementName = result?.[0] as HTMLElement;
      if (elementName) {
        if (scrollDirection === LayoutScrollType.UP && [Breakpoints.XS, Breakpoints.SM].includes(viewPortType)) {
          setMobileBottomNavigation(false);
        } else {
          setMobileBottomNavigation(true);
        }
      }
    }
  };

  function stickyHeaderhandler(name: string, replaceName: string) {
    const dataName = document.getElementsByClassName(name);
    if (dataName.length > 0) {
      const containerName = dataName[0] as HTMLElement;
      containerName.className = containerName.className.replace(name, replaceName);
    }
  }

  const productClickHandler = (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | undefined,
    id: string,
  ) => {
    router.push(`/product/${id}`);
  };
  const suggestionClickHandler = (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | undefined,
    text: string,
  ) => {
    router.push(`/search?text=${encodeURIComponent(text)}`);
  };

  const backClickHandler = (e: React.KeyboardEvent | React.MouseEvent) => {
    if (props.backHandler) {
      props.backHandler(e);
    } else {
      router.back();
    }
  };

  const languageHandler = (locale: string) => {
    const el = document.getElementById('cp-modal');
    if (el && el?.style.display !== 'none' && window.utag_data) {
      window.utag_data.site_language = locale;
      window.utag?.gdpr?.showExplicitConsent(locale);
    }
    cookieStorage.store(CookieStorageKeys.NEXT_LOCALE, `${locale}; path=/`);
    const replaceUri = getChangedLocaleUrl(locale, router.params?.locale as string);
    nextRouter.replace(replaceUri);
  };

  const onMePanelClick = (isMePanelDrawerOpenClicked: boolean) => {
    setIsMePanelDrawerOpen(isMePanelDrawerOpenClicked);
  };
  useEffect(() => {
    if (document.getElementById('impersonation-banner-id')) {
      setHasImpersonationBanner(true);
    }
  }, []);

  return (
    <>
      <div
        className={`${styles.container} ${props.headerProps?.isStaticHeader ? styles['static-header-available'] : ''} ${
          mobileTopBarScroll ? styles['layout-on-scroll'] : ''
        }`}
      >
        {!hideNotifications && isNotificationClosed && !isNotificationError && (
          <div id="notificationbar-header" className={styles['notificationbar-header']}>
            <AnnouncementBar
              notificationsCallback={(value: boolean) => setIsNotificationClosed(value)}
              header={header}
            />
          </div>
        )}

        {!hideNotifications && isNotificationError && (
          <div className={styles['notificationbar-error-header']} data-testid="error-notitfication">
            {' '}
            <NotificationBar
              message={t('amshop.errorNotification.message')}
              ctaLabel={t('amshop.reloadPage')}
              isShowMore={false}
              theme={Theme.RED}
              isCloseIconRequired={false}
              ctaClickHandler={reloadPage}
            />
          </div>
        )}

        {!props.hideSiteNavigation && header?.top_navigation?.utility_header_bar_display && (
          <span id="site-navigation-header" className={styles['site-navigation-header']}>
            <CmsComponent
              componentKey="top_navigation"
              data={header?.top_navigation}
              {...{
                activeRoute: router.pathname as string,
                locale: router.params?.locale,
                langHandler: languageHandler,
              }}
            />
          </span>
        )}
        <div
          id="mobile-topbar-navigation-header"
          className={`${styles['mobile-topbar-navigation-header-style']}
               ${isNotificationError && styles['error_notification_top_padding']}
               ${
                 props?.headerProps?.isStaticHeader &&
                 !mobileTopBarScroll &&
                 !props.hideLocationSelector &&
                 styles['header-height']
               }
          `}
        >
          {props.hasTopMobileHeader && (
            <MobileTopBarNavigation
              headerElement={props?.headerProps?.headerElement}
              href={'#'}
              hideLocationSelector={props.hideLocationSelector}
              isStaticLocationSelector={props.isStaticLocationSelector}
              hasCart={props?.headerProps?.hasCart}
              topBarScrolled={mobileTopBarScroll}
              isStaticHeader={props?.headerProps?.isStaticHeader}
              isBackNavigation={props?.headerProps?.isBackNavigation}
              logo={header?.logo}
              hasSearchBar={props?.headerProps?.hasSearchBar}
              moreActionContent={props?.headerProps?.moreActionContent}
              hasAmwayLogo={props.headerProps?.hasAmwayLogo}
              onProductClick={productClickHandler}
              onSuggestionClick={suggestionClickHandler}
              onBackClick={backClickHandler}
              headerTitle={props?.headerTitle}
            />
          )}
        </div>
        {!props.hideMobileBottomNavigation && Object.keys(header || {}).length > 0 && (
          <div
            id="mega-menu-header-container"
            className={`${
              hasImpersonationBanner
                ? styles['mega-menu-header-container-with-impersonation']
                : styles['mega-menu-header-container']
            } ${isMePanelDrawerOpen ? styles['mepanel-drawer-on-open'] : ''} ${
              mobileTopBarScroll ? styles['header-container-on-scroll'] : ''
            }
         ${mobileBottomNavigation ? styles['mobilBottom-header-on-scroll'] : ''}
         ${isNotificationError ? styles['error_notification_top_padding'] : ''}
         `}
          >
            <HeaderContainer
              activeRoute={activeRoute}
              header={header}
              tabletScrolled={mobileTopBarScroll}
              hasTabletScrolledHeaderBody={props?.headerProps?.hasTabletScrolledHeaderBody}
              headerBody={props?.headerProps?.headerElement}
              onProductClick={productClickHandler}
              onSuggestionClick={suggestionClickHandler}
              onMePanelClick={onMePanelClick}
              cartListId={myList?.listId}
              hasStickyBottomBar={stickyBottomHeader}
              locale={router.params?.locale as string}
              langHandler={languageHandler}
            />
          </div>
        )}
        <main
          className={`${styles.content} ${scrollType === LayoutScrollType.DOWN ? styles['content-on-scroll'] : ''} ${
            isNotificationError ? styles['sticky-top-bar-positions-with-error'] : ''
          }`}
          id="main-content"
          data-testid="main-content-test-id"
        >
          {[Breakpoints.LG, Breakpoints.MD, Breakpoints.XL].includes(viewPortType) && (
            <div className={styles['breadcrumb-container']} data-testid="breadcrumb-container-test-id">
              {props?.crumbsData && props?.crumbsData?.breadcrumbData?.length > 0 && (
                <Breadcrumb breadcrumbData={props?.crumbsData?.breadcrumbData} />
              )}
            </div>
          )}
          {props.children}
          <div className={styles['footer-section']}>
            {footer && !props.hideFooter && Object.keys(footer).length > 0 && (
              <div className={styles['footer-alignment']}>
                <CmsComponent
                  componentKey="footer"
                  data={
                    {
                      ...getAccountTypeData(footer, session.type),
                    } ?? {}
                  }
                  {...{
                    hideFooterNavigation: props?.footerProps?.hideFooterNavigation,
                    hideSocialIcon: props?.footerProps?.hideSocialIcon,
                    hideLegalLink: props?.footerProps?.hideLegalLink,
                    locale: router.params?.locale,
                    langHandler: languageHandler,
                  }}
                />
              </div>
            )}
          </div>
        </main>
      </div>
      {configs.NEXT_ADA_DATA_HANDLE && <AdaChatbot />}
    </>
  );
};

export default Layout;
