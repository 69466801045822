import { i18n } from 'i18n.config';
import { useState, useEffect } from 'react';
const { defaultLocale } = i18n;

const LayoutScrollType = {
  UP: 'UP',
  DOWN: 'DOWN',
};

const useLayoutScroll = () => {
  const [scrollType, setScrollType] = useState('');
  const [scrollPosition, setScrollPosition] = useState(0);
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);

  useEffect(() => {
    if (scrollPosition === 0) {
      setScrollType(LayoutScrollType.UP);
    }
  }, [scrollPosition]);

  const handleScrollType = () => {
    const currentScrollPosition = window.scrollY || document.documentElement.scrollTop;
    setScrollPosition(currentScrollPosition);
    if (prevScrollPosition > window.scrollY) {
      setScrollType(LayoutScrollType.UP);
    } else if (prevScrollPosition < window.scrollY) {
      setScrollType(LayoutScrollType.DOWN);
    }
    setPrevScrollPosition(window.scrollY);
  };

  return { scrollType, scrollPosition, prevScrollPosition, setPrevScrollPosition, handleScrollType };
};

const getChangedLocaleUrl = (locale: string, currentLocale: string) => {
  const pathname = window.location.pathname.replace(`/${currentLocale}`, '');
  return [locale !== defaultLocale ? `/${locale}` : '/', pathname, window.location.search].join('');
};
export { LayoutScrollType, useLayoutScroll, getChangedLocaleUrl };
