import { CmsComponent } from '@commerce-webcomponents/ui-composite';
import { useEffect } from 'react';
import { useAppSelector, AppState } from '../../feature/store';
import { AnnouncementBarProps } from './types/propsTypes';
import { useLayoutScroll } from 'utils/LayoutUtil';

const AnnouncementBar = (props: AnnouncementBarProps) => {
  const { notificationData } = useAppSelector((state: AppState) => state.amshop);
  const { setPrevScrollPosition } = useLayoutScroll();
  useEffect(() => {
    setPrevScrollPosition(0);
  }, [notificationData]);
  return (
    <>
      {notificationData && (
        <CmsComponent
          componentKey="notification"
          data={notificationData}
          {...{ callBack: (value: boolean) => props.notificationsCallback(value) }}
        />
      )}
    </>
  );
};
export default AnnouncementBar;
